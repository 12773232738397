
import { mapGetters, mapMutations, mapState, mapActions } from "vuex"
import { BSkeletonImg, BSkeleton } from 'bootstrap-vue'
import NeedHelpPopup from '~/components/UI/need-help-popup.vue'

export default {
  name: "Homepage",
  components: {
    'b-skeleton-img': BSkeletonImg,
    'b-skeleton': BSkeleton,
    NeedHelpPopup
  },
  async asyncData ({ $axios, $replaceSEO }) {
    const seo = await $axios.get('sitemaps/home')
    const promoCode = await $axios.get('get-promo-codes')
    // get last promo code for show popup
    const lastPromoCode = await $axios.get('get-last-promocode-active')
    // console.log(',,, lastPromoCode ,,,', lastPromoCode)
    let promoCodePopup = null
    if (lastPromoCode) {
      promoCodePopup = lastPromoCode.data
    }
    const customers = [
      {
        img: "customer/Customer1/83_1.png",
        cover_video: "customer/Customer1/SiamMotobike2.jpg",
        video: "customer/Customer1/SiamMotobike.mp4",
        text: '"อยากทำกล่องของตัวเองเพราะว่าอยากโฆษณาแบรนด์ตัวเองเป็นหลัก อยากทำแพคเกจจิ้งที่ดูพรีเมี่ยม ดูดี ดูแข็งแรง เวลาเราส่งมอบให้ลูกค้า มันเป็นความรู้สึก First Impression ที่ดีครับ"',
        avatar: "customer/Customer1/customer1.png",
        name: "คุณศิรพงศ์",
        brand: "เจ้าของแบรนด์ Siam Motobike",
      },
      {
        img: "customer/Customer2/Product Pic2.png",
        cover_video: "customer/Customer2/Nick_LocoPack2.jpg",
        video: "customer/Customer2/Nick_LocoPack.mp4",
        text: '"ทุกแบรนด์เราต้องหาดีเอ็นเอของเราให้เจอก่อน เราต้องรู้ เราต้องทราบว่าดีเอ็นเอของเราคืออะไร Character ของแบรนด์เราเป็นอย่างไร แล้วสิ่งที่ตามมา Product ก็ตาม กล่องก็ตาม ถุงก็ตาม หรือแม้แต่โลโก้ของเรา มันต้องไปในทิศทางเดียวกัน"',
        avatar: "customer/Customer2/Customer2.png",
        name: "คุณนิค",
        brand: "Founder ของ BRISUTHI",
      },
      {
        img: "customer/Customer3/Product Pic1.png",
        cover_video: "customer/Customer3/Beta_LocoPack.jpg",
        video: "customer/Customer3/Beta_LocoPack.mp4",
        text: "“เวลาเราทำProduct แล้วมันมีคุณค่าในตัว อย่างเสื้อทหารมันมีประวัติศาสตร์ เราก็อยากให้มันรู้สึกอยู่ใน Position ที่ระดับกลางหรือบน เพราะฉะนั้น Packaging ก็จะเป็นส่วนที่เสริมให้ Product เราดูอยู่ตรงนั้นจริงๆ ไม่ใช่แค่ราคา”",
        avatar: "customer/Customer3/customerPic3.png",
        name: "คุณแจ๊ค",
        brand: "Beta Company",
      }
    ]
    const stepWorking = [
      {
        title: "เลือกสินค้า",
        text: "ระบุรายละเอียดสินค้าที่ต้องการ",
        image: "https://locopack-files.s3.ap-southeast-1.amazonaws.com/step_working/step1.png",
      },
      {
        title: "ส่งแบบเพื่อผลิต",
        text: "หากยังไม่มีไฟล์ สามารถให้เราออกแบบให้ได้",
        image: "https://locopack-files.s3.ap-southeast-1.amazonaws.com/step_working/step2.png",
      },
      {
        title: "ชำระค่าสินค้าทั้งหมด",
        text: "ผ่านบัตรเครดิต หรือการโอนเงินผ่านธนาคาร ",
        image: "https://locopack-files.s3.ap-southeast-1.amazonaws.com/step_working/step3.png",
      },
      {
        title: "ยืนยันแบบ",
        text: "ตรวจขนาดและข้อความเพื่อเตรียมการผลิต",
        image: "https://locopack-files.s3.ap-southeast-1.amazonaws.com/step_working/step4.png",
      },
      {
        title: "จัดส่ง ",
        text: "หลังจากผลิตเสร็จเรียบร้อย เรามีบริการจัดส่งให้ถึงที่หมาย",
        image: "https://locopack-files.s3.ap-southeast-1.amazonaws.com/step_working/step5.png",
      },
    ]
    return {
      promoCode: promoCode.data,
      promoCodePopup,
      customers,
      stepWorking,
      seo: $replaceSEO(seo.data.data, null, null, null, null, null)
    }
  },
  data () {
    return {
      waypointLoaded: [],
      slide: 0,
      tabLeft: false,
      tabRight: true,
      tabItem: 'all',
      videoUrlBanner: '',
      banners: [
        {
          "banner_id": 16,
          "sort": 1,
          "banner_type": 1,
          "banner_position": "banner_slide1",
          "banner_path": "banners/1688609912_Desktop.jpg",
          "banner_path_mobile": "banners/1680845920_Mobile.png",
          "video_url": null,
          "banner_url": "https://www.locopack.co/th/products",
          "status": null,
          "created_at": "2021-12-08 04:54:44",
          "updated_at": "2024-01-15 09:48:00",
          "deleted_at": null
        }
      ],
      homepage_features: [],
      sampleProductCategories: [
        {
          "id": 3,
          "name": "เครื่องสำอาง",
          "img": "https://locopack-files.s3-ap-southeast-1.amazonaws.com/sample_product_category/1619200954_Cosmetic4.png",
          "type": 1,
          "sorting": 99,
          "mapping_input_id": null,
          "status": 1,
          "seo_h1": null,
          "created_at": "2020-04-10 13:38:15",
          "updated_at": "2022-12-21 14:15:53",
          "deleted_at": null,
          "graphic": {
            "id": 16261,
            "type_id": 32,
            "sub_id": 3,
            "rel_patterns_id": null,
            "sub_id2": null,
            "path": "sample_product_category_graphic/1671606953_Big_เครื่องสำอางpng.png",
            "short_url": null,
            "link": null,
            "created_at": "2022-12-21 07:15:53",
            "updated_at": null,
            "deleted_at": null,
            "created_at_text": "21-12-2022 07:15:53"
          }
        },
        {
          "id": 4,
          "name": "อาหาร",
          "img": "https://locopack-files.s3-ap-southeast-1.amazonaws.com/sample_product_category/1619200965_Food4.png",
          "type": 1,
          "sorting": 98,
          "mapping_input_id": null,
          "status": 1,
          "seo_h1": null,
          "created_at": "2020-04-10 13:38:26",
          "updated_at": "2022-12-21 14:16:10",
          "deleted_at": null,
          "graphic": {
            "id": 16263,
            "type_id": 32,
            "sub_id": 4,
            "rel_patterns_id": null,
            "sub_id2": null,
            "path": "sample_product_category_graphic/1671606970_Big_อาหาร.png",
            "short_url": null,
            "link": null,
            "created_at": "2022-12-21 07:16:10",
            "updated_at": null,
            "deleted_at": null,
            "created_at_text": "21-12-2022 07:16:10"
          }
        },
        {
          "id": 7,
          "name": "ของใช้",
          "img": "https://locopack-files.s3-ap-southeast-1.amazonaws.com/sample_product_category/1619201003_HouseDecor4.png",
          "type": 1,
          "sorting": 97,
          "mapping_input_id": null,
          "status": 1,
          "seo_h1": null,
          "created_at": "2020-04-10 13:39:10",
          "updated_at": "2022-12-21 14:17:05",
          "deleted_at": null,
          "graphic": {
            "id": 16268,
            "type_id": 32,
            "sub_id": 7,
            "rel_patterns_id": null,
            "sub_id2": null,
            "path": "sample_product_category_graphic/1671607025_Big_ของใช้ในบ้าน.png",
            "short_url": null,
            "link": null,
            "created_at": "2022-12-21 07:17:05",
            "updated_at": null,
            "deleted_at": null,
            "created_at_text": "21-12-2022 07:17:05"
          }
        },
        {
          "id": 9,
          "name": "ผลไม้",
          "img": "https://locopack-files.s3-ap-southeast-1.amazonaws.com/sample_product_category/1619200909_Fruit.png",
          "type": 1,
          "sorting": 96,
          "mapping_input_id": null,
          "status": 1,
          "seo_h1": null,
          "created_at": "2021-03-22 13:33:56",
          "updated_at": "2022-12-21 14:16:35",
          "deleted_at": null,
          "graphic": {
            "id": 16265,
            "type_id": 32,
            "sub_id": 9,
            "rel_patterns_id": null,
            "sub_id2": null,
            "path": "sample_product_category_graphic/1671606995_Big_ผลไม้.png",
            "short_url": null,
            "link": null,
            "created_at": "2022-12-21 07:16:35",
            "updated_at": null,
            "deleted_at": null,
            "created_at_text": "21-12-2022 07:16:35"
          }
        }
      ],
      productGroupCategories: [],
      patternsSort: [],
      patternsCat: [],
      webmIsSupport: true,
      cover_video: [true, true, true],
      loadMore: false,
      articles: [],
      articles_promotion: []
    }
  },
  computed: {
    ...mapState(["userData"]),
    ...mapGetters(["isAuthenticated"]),
  },
  async mounted () {
    // console.log('ENV :: ', process.env.NODE_ENV)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "pageInfo",
      page: {
        type: "home",
        path: `${this.$route.path}`,
        title: this.seo.title,
      },
      user: {
        customerID: this.userData ? this.userData.uid : "",
        customerEmail: this.userData ? this.userData.email : "",
      },
    });
    this.hideLoading();

    const checkWebm = document.createElement("video");
    if (checkWebm.canPlayType) {
      // Check for Webm support
      this.webmIsSupport =
        checkWebm.canPlayType('video/webm; codecs="vp8, vorbis"') !== "";
    } else {
      this.webmIsSupport = false;
    }

    // check save promocode in local storage
    // console.log('isAuthennnn', this.isAuthenticated);
    if (this.isAuthenticated) {
      // if user logined get promocode in storage save
      const promoCodeStorage = JSON.parse(localStorage.getItem("promoCode")) || [];
      if (promoCodeStorage) {
        for (const item of promoCodeStorage) {
          try {
            await this.$axios.$post("save-promo-code", { code: item.code });
          } catch (err) {}
        }
        localStorage.removeItem("promoCode");
      }
    }
    this.showpopup();

    setTimeout(() => {
      this.videoUrl = "https://www.youtube.com/embed/DW5L3vtg3kI";
    }, 5000);
    setTimeout(() => {
      window.$(".read-more").readMore({ previewHeight: 160 });
    }, 1500);
    this.sendDataLayer();
    this.waypointLoaded = [];

    const banners = await this.$axios.get('banners')
    this.banners = banners.data.banner_slide
    this.homepage_features = banners.data.homepage_feature
    const sampleProductCategories = await this.$axios.get('sample_product_categories')
    this.sampleProductCategories = sampleProductCategories.data.data
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "setAlert"]),
    ...mapActions(["getAllCookies", "clearCookieAll"]),
    showCustomerVideo(index) {
      this.cover_video[index] = false;
      // this.$refs['video_customer' + index].play()
    },
    clickOnBanner(index, bannerUrl, videoUrl) {
      const banner = this.banners[index];
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "promoClicked",
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: "",
                name: String(banner.banner_path).replace(
                  /banners\/|\.jpg|\.png|\.gif/gi,
                  ""
                ),
                position: index + 1,
              },
            ],
          },
        },
      });
      if (videoUrl) {
        this.videoUrlBanner = videoUrl;
        this.$bvModal.show("modal-video-banner");
      } else if (bannerUrl) {
        window.location = bannerUrl;
      }
    },
    validateYouTubeUrl(urlToParse) {
      if (urlToParse) {
        const regExp =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (urlToParse.match(regExp)) {
          return true;
        }
      }
      return false;
    },
    onWaypoint ({ going, direction, el }) {
      if (going === this.$waypointMap.GOING_IN && (direction === this.$waypointMap.DIRECTION_TOP || direction === this.$waypointMap.DIRECTION_BOTTOM || direction === undefined)) {
        const sectionName = el.getAttribute('data-section-name')
        if (this.waypointLoaded[sectionName] === undefined) {
          const functionName = `get${sectionName}`
          this[functionName]()
          this.waypointLoaded[sectionName] = true
        }
      }
    },
    getPatternImg(pattern) {
      return pattern.cover ? `${this.$store.state.s3}${pattern.cover.path}` : `${this.$store.state.s3}product_set_image_open/default-image.png`
      let url = this.$store.state.s3 + 'product_set_image_open/default-image.png'
      if (pattern.pattern_img && pattern.pattern_img[0]) {
        if (pattern.pattern_img[0].path) {
          url = this.$store.state.s3 + pattern.pattern_img[0].path
        } else {
          url = pattern.pattern_img[0]
        }
      } else if (pattern.img && pattern.img[0]) { // ต้องปรับให้ชื่อ array ที่ส่งมาเหมือนกัน
        if (pattern.img[0].path) {
          url = this.$store.state.s3 + pattern.img[0].path
        } else {
          url = pattern.img[0]
        }

      }
      return this.$imgProxy.getUrl(url, 250, 0)
    },
    async getProductGroupCategory () {
      const productGroupCategories = await this.$axios.get('product-group-categories')
      this.productGroupCategories = productGroupCategories.data[0]
    },
    getAllPackaging () {
      this.$axios.$get('product-categories').then(res => {
        this.patternsSort = res
      })
    },
    async getArticle () {
      const articles = await this.$axios.get("articles", { params: { limit: 4, forHomepage: 1 }})
      const articles_promotion = await this.$axios.get("articles_promotion", { params: { limit: 4, forHomepage: 1 }})
      this.articles = articles.data.data
      this.articles_promotion = articles_promotion.data.data
    },
    productClicked(pattern, indexPT) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "productClicked",
        ecommerce: {
          click: {
            actionField: { list: "Homepage" },
            products: [
              {
                id: `LOCOPACK_${pattern.packaging_type_id}` + pattern.id.toString().padStart(9, "0"),
                name: pattern.name,
                price: pattern.per_pcs,
                category: this.patternsSort[indexPT].packaging_type.packaging_type_name,
                brand: "Locopack",
                position: 1,
                dimension1: "",
                dimension2: "",
                dimension3: "",
                dimension4: "",
                dimension5: "",
                dimension6: "",
                dimension7: "",
                dimension8: "",
                dimension9: "",
                dimension10: "",
                dimension11: "",
                dimension12: "",
                dimension13: ""
              }
            ]
          }
        }
      })
    },
    savePromoCode(code, name) {
      if (this.isAuthenticated) {
        this.showLoading();
        try {
          this.$axios.$post("save-promo-code", { code });
        } catch (err) {}
        this.hideLoading();
      } else {
        let promoCode = JSON.parse(localStorage.getItem("promoCode")) || [];
        if (promoCode) {
          // ปรับให้ code popup เก็บไว้แค่อันเดียวพอ
          const codePopup = promoCode.filter((o) => o.code === code);
          if (codePopup.length === 0) {
            promoCode.push({ code, name });
          }
        } else {
          promoCode = [{ code, name }];
        }
        localStorage.setItem("promoCode", JSON.stringify(promoCode));
      }
      this.setAlert({
        show: true,
        message: "บันทึกโค้ดส่วนลด: " + code + " เรียบร้อยแล้ว",
        header: "ดำเนินการ",
      });
      setTimeout(() => {
        this.hideAlert()
      }, 2000)
    },
    hideAlert () {
      this.$store.commit('setAlert', { show: false, message: '' })
    },
    viewArticle(itemId) {
      return { name: "articles-id", params: { id: itemId } }
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    slideLeft (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft -= width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    slideRight (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft += width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    onScroll (e) {
      const sLeft = this.$refs.scrollingWrapper.scrollLeft
      const sRight = (this.$refs.scrollingWrapper.scrollWidth - this.$refs.scrollingWrapper.scrollLeft)
      if (sLeft <= 20) {
        this.tabLeft = false
        this.tabRight = true
      }
      if (sRight === 226) {
        this.tabRight = false
        this.tabLeft = true
      }
      if (sLeft >= 20 && sRight !== 226) {
        this.tabLeft = true
        this.tabRight = true
      }
    },
    async selectedTab (pkId, index, product_type) {
      this.tabItem = index
      let data = null
      if (index !== 'all') {
        data = await this.$axios.$get('products', {
          params: { packaging_type_id: pkId, without_category: 1, product_type }
        })
      } else {
        data = await this.$axios.$get('products', {
          params: { without_category: 1 }
        })
      }
      this.patternsCat = data.products
    },
    modalImageClick() {
      this.$refs["modal-promotion-popup"].hide();
    },
    showpopup() {
      const promoCodePopupStorage = JSON.parse(
        localStorage.getItem("promoCodePopupStorage")
      );
      if (promoCodePopupStorage) {
        if (new Date().getTime() <= promoCodePopupStorage) {
          return;
        } else {
          // 10800000 = 3 ชั่วโมง
          localStorage.setItem(
            "promoCodePopupStorage",
            JSON.stringify(new Date().getTime() + 10800000)
          );
        }
      } else {
        // 10800000 = 3 ชั่วโมง
        localStorage.setItem(
          "promoCodePopupStorage",
          JSON.stringify(new Date().getTime() + 10800000)
        );
      }
      if (this.promoCodePopup.code && this.$refs["modal-promotion-popup"]) {
        this.$refs["modal-promotion-popup"].show();
        setTimeout(() => {
          this.$refs["modal-promotion-popup"].hide();
        }, 2000)
      }
    },
    sendDataLayer() {
      const promotions = [];
      for (let i = 0; i < this.banners.length; i++) {
        const banner = this.banners[i];
        promotions.push({
          id: "",
          name: String(banner.banner_path).replace(
            /banners\/|\.jpg|\.png|\.gif/gi,
            ""
          ),
          position: i + 1,
        });
      }
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "promoViewed",
        ecommerce: {
          promoView: {
            promotions,
          },
        },
      });
    },
    async showModal (banner) {
      if(banner.banner_position === 'banner_slide4') {
        await this.$firebaseBannerPlugin()
        window.location.href = "https://bit.ly/3ZxXeFz"
        // window.location.href = "https://line.me/R/ti/p/@gwc6379j?oat__id=1303339"
      }
    }
  }
}
